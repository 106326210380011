import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import axios from "../lib/axios.js";
import RHFMultiSelect from "./RHFMultiSelect.jsx";
import VirtualizedAutocomplete from "./VirtualizedAutocomplete";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TestResultsInformation from "./TestResultsInformation";
import { Box, Checkbox, FormControl, Grow } from "@mui/material";
import { setLabExpanded, setNoLabChecked } from "../store/features/conditionSlice.js";
import { formatPhone } from "../lib/misc.js";
import { setLabSelected } from "../store/features/labSlice.js";
import { setDefaultOrderAndLab } from "../helpers/noLabHelper.js";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function LabInformation(props) {
  const { counties, states, labs, no_lab } = useSelector((state) => state.caseDropdown.caseDropdown);
  const { conditionSpecimens } = useSelector((state) => state.condition);
  const [filteredCounties, setFilteredCounties] = React.useState([]);
  const [sameAsReporter, setSameAsReporter] = React.useState(false);
  const { labSelected } = useSelector((state) => state.lab);
  const [facilities, setFacilities] = React.useState([]);
  const [stateChanged, setStateChanged] = useState(false);
  const { noLabChecked, labExpanded } = useSelector((state) => state.condition);
  const { labReportRequired } = useSelector((state) => state.condition);
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    if (!noLabChecked) dispatch(setLabExpanded(!labExpanded));
  };
  const handleStateChange = (e, v) => {
    if (v?.id) {
      setStateChanged(true);
    }
    props.setValue("lab_test.lab.address.state_id", v);
    const c = counties.filter((county) => county.state_id === v.id);
    setFilteredCounties(c);
  };

  const handleToggleSameAsReporter = (event) => {
    if (props?.caseId) return;
    setSameAsReporter(event.target.checked);
    const values = props.getValues();
    if (event.target.checked) {
      props.setValue("lab_test.lab", values.facility, { shouldValidate: true });
      const facility = values.facility;
      const clinician = values.clinician;
      props.setValue("lab_test.lab.name", facility.name, {
        shouldValidate: true,
      });
      props.setValue("lab_test.lab.address.street", facility.address.street, { shouldValidate: true });
      props.setValue("lab_test.lab.address.unit", facility.address.unit, { shouldValidate: true });
      props.setValue("lab_test.lab.address.city", facility.address.city, { shouldValidate: true });
      props.setValue("lab_test.lab.address.zip", facility.address.zip, { shouldValidate: true });
      props.setValue(
        "lab_test.lab.address.state_id",
        states.find((state) => state.id === facility.address.state_id.id),
        { shouldValidate: true }
      );
      props.setValue(
        "lab_test.lab.address.county_id",
        counties.find((county) => county.id === facility.address.county_id.id),
        { shouldValidate: true }
      );
      props.setValue("lab_test.lab.area_code", clinician.area_code, {
        shouldValidate: true,
      });
      props.setValue("lab_test.lab.phone_number", clinician.phone_number, { shouldValidate: true });
      props.setValue("lab_test.lab.phone", formatPhone(clinician.area_code, clinician.phone_number) || "", { shouldValidate: true });
    } else {
      props.setValue("lab_test.lab", {
        name: "",
        address: {
          city: "",
          county_id: "",
          state_id: "",
          unit: "",
          street: "",
          zip: "",
        },
        area_code: "",
        phone_number: "",
        phone: "",
      });
      handleLabSelection(null, null);
    }
  };

  useEffect(() => {
    if (!props.caseId) {
      setSameAsReporter(false);
    }
  }, [props.caseId]);

  useEffect(() => {
    if (!props.caseId && !stateChanged) {
      handleStateChange(
        null,
        states?.find((s) => s.code === process.env.DEFAULT_STATE)
      );
    }
  }, [props]);

  // Fetching and initalizing the facilities, states, and counties used in autocomplete components
  useEffect(() => {
    axios
      .get("/api/facilities/")
      .then((resp) => {
        setFacilities(resp.data.facilities);
      })
      .catch((e) => {
        console.log("Error fetching option sources from /facilities: ", e);
      }); // TODO: Better error handling
  }, [setFacilities]);

  const handleNoLab = async (e) => {
    props.setValue("noLabReported", e.target.checked);
    if (e.target.checked) {
      dispatch(setLabExpanded(false));
    } else {
      dispatch(setLabExpanded(true));
    }
    setDefaultOrderAndLab(props.setValue, props.getValues, e.target.checked, no_lab);
    dispatch(setNoLabChecked(e.target.checked));
    await props.trigger();
  };

  const handleLabSelection = (e, v) => {
    dispatch(setLabSelected(false));
    if (v === null) {
      props.setValue("lab_test.lab.name", null);
      props.setValue("lab_test.lab.address.street", null);
      props.setValue("lab_test.lab.address.unit", null);
      props.setValue("lab_test.lab.address.city", null);
      props.setValue("lab_test.lab.address.zip", null);
      props.setValue("lab_test.lab.address.state_id", null);
      props.setValue("lab_test.lab.address.county_id", null);
      props.setValue("lab_test.lab.area_code", null);
      props.setValue("lab_test.lab.phone_number", null);
      props.setValue("lab_test.lab.phone", null);
      return;
    }
    let testingLab = labs.find((lab) => lab.name === v.name && lab.type === "lab");
    if (!testingLab) {
      testingLab = labs.find((lab) => lab.name === v.name && lab.type === "facility");
    }
    if (testingLab) {
      dispatch(setLabSelected(true));
      props.setValue("lab_test.lab.name", testingLab.name, {
        shouldValidate: true,
      });
      props.setValue("lab_test.lab.address.street", testingLab.address.street, { shouldValidate: true });
      props.setValue("lab_test.lab.address.unit", testingLab.address.unit, { shouldValidate: true });
      props.setValue("lab_test.lab.address.city", testingLab.address.city, { shouldValidate: true });
      props.setValue("lab_test.lab.address.zip", testingLab.address.zip, { shouldValidate: true });
      props.setValue(
        "lab_test.lab.address.state_id",
        states.find((state) => state.id === testingLab.address.state_id),
        { shouldValidate: true }
      );
      props.setValue(
        "lab_test.lab.address.county_id",
        counties.find((county) => county.id === testingLab.address.county_id),
        { shouldValidate: true }
      );
      props.setValue("lab_test.lab.area_code", testingLab.area_code, {
        shouldValidate: true,
      });
      props.setValue("lab_test.lab.phone_number", testingLab.phone_number, { shouldValidate: true });
    }
  };

  const handleNewLab = (e) => {
    props.setValue("lab_test.lab.name", e.target.value);
  };

  const labPhone =
    props.caseId || sameAsReporter ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="lab_test.lab.phone"
            fullWidth={true}
            inputProps={{ readOnly: props.readonly || sameAsReporter }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            disabled={sameAsReporter || labSelected}
            label="Phone Number"
            data-testid="lab_test.lab.phone"
            {...props.register("lab_test.lab.phone")}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            disabled={sameAsReporter || labSelected}
            name="lab_test.lab.area_code"
            inputProps={{ readOnly: props.readonly }}
            label="Area Code"
            variant="standard"
            error={!!props.errors.lab_test?.lab?.area_code}
            helperText={props.errors.lab_test?.lab?.area_code && props.errors.lab_test?.lab?.area_code.message}
            {...props.register("lab_test.lab.area_code")}
            InputLabelProps={{
              shrink: props.watch("lab_test.lab.area_code") !== "",
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            disabled={sameAsReporter || labSelected}
            name="lab_test.lab.phone_number"
            inputProps={{ readOnly: props.readonly }}
            label="Phone Number"
            variant="standard"
            error={!!props.errors.lab_test?.lab?.phone_number}
            helperText={props.errors.lab_test?.lab?.phone_number && props.errors.lab_test?.lab?.phone_number.message}
            {...props.register("lab_test.lab.phone_number")}
            InputLabelProps={{
              shrink: props.watch("lab_test.lab.phone_number") !== "",
            }}
          />
        </Grid>
      </Grid>
    );

  return (
    <Card className="mt-3" variant="outlined">
      <CardHeader
        action={
          !noLabChecked && (
            <ExpandMore expand={labExpanded} onClick={handleExpandClick} aria-expanded={labExpanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          )
        }
        title={<p className="m-0 card-header">LAB INFORMATION</p>}
        subheader={
          !labReportRequired && (
            <Box sx={{ display: "flex" }}>
              <Grow in={!labReportRequired} style={{ transformOrigin: "0 0 0" }} {...(!labReportRequired ? { timeout: 1000 } : {})}>
                <FormControlLabel
                  control={<Checkbox disabled={props.caseId} name="noLabReported" onChange={(e) => handleNoLab(e)} checked={noLabChecked} />}
                  label="No Lab Reported"
                />
              </Grow>
            </Box>
          )
        }
      />
      <Collapse in={labExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid className="py-3" container spacing={3}>
            {isNaN(props.caseId) && (
              <Grid item xs={12}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={sameAsReporter}
                      disabled={props?.caseId}
                      onChange={handleToggleSameAsReporter}
                      inputProps={{
                        "aria-label": "controlled",
                        disabled: false,
                      }}
                    />
                  }
                  label={<Typography variant="subtitle2">Same as reporter</Typography>}
                  labelPlacement="end"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {isNaN(props.caseId) && !sameAsReporter ? (
                <VirtualizedAutocomplete
                  options={labs}
                  getOptionLabel={(o) => (o?.id ? `${o?.name}` : "")}
                  getOptionSelected={(o) => o?.name || ""}
                  label="Testing Lab *"
                  freeSolo={true}
                  error={!!props.errors.lab_test?.lab?.name}
                  fullWidth
                  readOnly={props.readonly}
                  control={props.control}
                  helperText={props.errors.lab_test?.lab?.name && props.errors.lab_test?.lab?.name?.message}
                  register={props.register}
                  fieldName="lab_test.lab.name"
                  changeme={handleLabSelection}
                  onTextChange={handleNewLab}
                  registerOpts={{
                    required: { value: true, message: "Required" },
                    maxLength: {
                      value: 100,
                      message: "Must be less than 100 characters",
                    },
                  }}
                />
              ) : (
                <FormControl fullWidth>
                  <TextField
                    name="lab_test.lab.name"
                    {...props.register("lab_test.lab.name")}
                    inputProps={{ readOnly: true }}
                    label="Lab *"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={sameAsReporter || labSelected}
                InputProps={{ readOnly: props.readonly }}
                InputLabelProps={{
                  shrink: props.watch("lab_test.lab.address.street") !== "",
                }}
                label="Street *"
                variant="standard"
                className="w-100"
                {...props.register("lab_test.lab.address.street")}
                error={!!props.errors.lab_test?.lab?.address?.street}
                helperText={props.errors.lab_test?.lab?.address?.street && props.errors.lab_test?.lab?.address?.street.message}
                inputProps={{ "data-testid": "lab_test.lab.address.street" }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled={sameAsReporter || labSelected}
                InputProps={{ readOnly: props.readonly }}
                label="Unit"
                variant="standard"
                className="w-100"
                {...props.register("lab_test.lab.address.unit")}
                InputLabelProps={{
                  shrink: props.watch("lab_test.lab.address.unit") !== "",
                }}
                error={!!props.errors.lab_test?.lab?.address?.unit}
                helperText={props.errors.lab_test?.lab?.address?.unit && props.errors.lab_test?.lab?.address?.unit.message}
                inputProps={{ "data-testid": "lab_test.lab.address.unit" }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled={sameAsReporter || labSelected}
                InputProps={{ readOnly: props.readonly }}
                label="City *"
                variant="standard"
                className="w-100"
                {...props.register("lab_test.lab.address.city")}
                InputLabelProps={{
                  shrink: props.watch("lab_test.lab.address.city") !== "",
                }}
                error={!!props.errors.lab_test?.lab?.address?.city}
                helperText={props.errors.lab_test?.lab?.address?.city && props.errors.lab_test?.lab?.address?.city.message}
                inputProps={{ "data-testid": "lab_test.lab.address.city" }}
              />
            </Grid>
            <Grid item xs={1}>
              <RHFMultiSelect
                disabled={sameAsReporter || labSelected}
                name="lab_test.lab.address.state_id"
                fullWidth
                register={props.register}
                readOnly={props.readonly}
                control={props.control}
                label="State *"
                options={states}
                onChange={handleStateChange}
                getOptionLabel={(o) => o?.code || ""}
                getOptionSelected={(o) => o?.code || ""}
                error={!!props.errors.lab_test?.lab?.address?.state_id}
                helperText={props.errors.lab_test?.lab?.address?.state_id && props.errors.lab_test?.lab?.address?.state_id.message}
                objGOS={true}
                inputProps={{ "data-testid": "lab_test.lab.address.state_id" }}
              />
            </Grid>
            <Grid item xs={2}>
              <RHFMultiSelect
                disabled={sameAsReporter || labSelected}
                name="lab_test.lab.address.county_id"
                fullWidth
                readOnly={props.readonly}
                register={props.register}
                label="County *"
                getOptionLabel={(o) => o?.name || ""}
                getOptionSelected={(o) => o?.name || ""}
                control={props.control}
                options={filteredCounties}
                variant="standard"
                error={!!props.errors.lab_test?.lab?.address?.county_id}
                helperText={props.errors.lab_test?.lab?.address?.county_id && props.errors.lab_test?.lab?.address?.county_id.message}
                objGOS={true}
                inputProps={{ "data-testid": "lab_test.lab.address.county_id" }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled={sameAsReporter || labSelected}
                label="Zip *"
                variant="standard"
                className="w-100"
                InputProps={{ readOnly: props.readonly }}
                {...props.register("lab_test.lab.address.zip")}
                InputLabelProps={{
                  shrink: props.watch("lab_test.lab.address.zip") !== "",
                }}
                error={!!props.errors.lab_test?.lab?.address?.zip}
                helperText={props.errors.lab_test?.lab?.address?.zip && props.errors.lab_test?.lab?.address?.zip.message}
                inputProps={{ "data-testid": "lab_test.lab.address.zip" }}
              />
            </Grid>
            <Grid item xs={4}>
              {labPhone}
            </Grid>
            <Grid className="py-3" item xs={12}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Specimen</strong>
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={4}>
                  <RHFMultiSelect
                    name="order.specimen_source_id"
                    fullWidth
                    register={props.register}
                    addBlankOption={true}
                    label="Specimen Source *"
                    control={props.control}
                    options={conditionSpecimens}
                    getOptionLabel={(o) => o?.name || ""}
                    getOptionSelected={(o) => o?.name || ""}
                    error={!!props.errors.order?.specimen_source_id}
                    helperText={props.errors.order?.specimen_source_id && props.errors.order?.specimen_source_id.message}
                    objGOS={true}
                    readOnly={props.readonly}
                    inputProps={{ "data-testid": "order.specimen_source_id" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className="w-100"
                    variant="standard"
                    name="order.specimen_collection_date"
                    type="date"
                    label="Specimen Collection Date *"
                    pattern="\d{4}-\d{2}-\d{2}"
                    InputProps={{
                      "data-testid": "order.specimen_collection_date",
                      readOnly: props.readonly,
                      max: "9999-12-31",
                    }}
                    {...props.register("order.specimen_collection_date")}
                    InputLabelProps={{ shrink: true }}
                    error={!!props.errors.order?.specimen_collection_date}
                    helperText={props.errors.order?.specimen_collection_date && props.errors.order.specimen_collection_date.message}
                  />
                </Grid>
              </Grid>
            </Grid>
           <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Test Results</strong>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Results listed below are for a single specimen source only. To add results for additional specimen sources, please create a new case record.<br/>
                Result value is a numerical field only.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TestResultsInformation {...props} />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
LabInformation.propTypes = {
  errors: PropTypes.shape({
    clinician: PropTypes.shape({
      area_code: PropTypes.object,
      phone_number: PropTypes.object,
    }),
  }),
  caseId: PropTypes.string,
  setValue: PropTypes.func,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentUser: PropTypes.object,
  clinLastName: PropTypes.bool,
  setClinLastName: PropTypes.func,
};
