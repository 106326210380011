export const setDefaultOrderAndLab = (setValue,getValues, noLab, no_lab_data = {}) => {
  const admissionDate = getValues().event.admitted_date;
  if (!noLab) {
    setValue("order.specimen_source_id", null)
    setValue("order.specimen_collection_date", null)
    const labDefault = {
      lab: {
        name: null,
        address: {
          street: null,
          unit: null,
          city: null,
          zip: null,
          state_id: null,
          county_id: null,
        },
        area_code: null,
        phone_number: null,
      },
      accession_number: null,
      test_date: null,
      lab_test_results: [
        {
          test_type_id: null,
          test_result_id: null,
          result_value: null,
        },
      ],
    };
    setValue("lab_test", labDefault);
  } else {
    setValue("order.specimen_source_id", no_lab_data.specimen_source)
    admissionDate ? setValue("order.specimen_collection_date", admissionDate) : setValue("order.specimen_collection_date", new Date().toISOString().split("T")[0])
    const noReportLabValue = {
      lab: {
        name: no_lab_data.lab.name,
        address: {
          street: no_lab_data.lab.address.street,
          unit: no_lab_data.lab.address.unit,
          city: no_lab_data.lab.address.city,
          zip: no_lab_data.lab.address.zip,
          state_id: no_lab_data.lab.address.state,
          county_id: no_lab_data.lab.address.county,
        },
        area_code: no_lab_data.lab.area_code,
        phone_number: no_lab_data.lab.phone_number,
      },
      accession_number: null,
      test_date: admissionDate ? admissionDate : new Date().toISOString().split("T")[0],
      lab_test_results: [
        {
          test_type_id: no_lab_data.lab_test_result.test_type,
          test_result_id: no_lab_data.lab_test_result.test_result,
          result_value: no_lab_data.lab_test_result.result_value,
        },
      ],
    };

    setValue("lab_test", noReportLabValue);
  }
};
